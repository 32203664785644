import React from 'react'
import './Comites.scss'

import Accordion from './components/Accordion/Accordion.tsx'

const Comites = () => {
    return (
        <div className='comites'>
            <div className="container">
                <h1>Les postes exécutifs</h1>
                <Accordion title='Président' description="Le rôle du/de la président(e) est crucial pour la gestion de l'association. Il/elle est responsable de définir sa vision, de superviser l'ensemble de l'organisation et de veiller au bon fonctionnement de toutes les activités. Le/la président(e) doit également s'assurer que les membres du comité et les bénévoles collaborent de manière efficace pour atteindre les objectifs de l'association. Il/elle doit encourager la communication ouverte et constructive entre les membres et les participants afin de maintenir une ambiance positive et motivante pour tous. Le/la président(e) doit donc mettre à profit ses compétences en leadership pour favoriser un environnement harmonieux au sein de l'association."/>

                <Accordion title='Secrétaire' description="Le rôle du/de la secrétaire est de soutenir la gestion efficace de l'association en structurant les réunions de l'exécutif, en produisant des comptes-rendus précis et en assurant le suivi des actions et des décisions prises lors des réunions. Pour remplir ses responsabilités de manière efficace, le/la secrétaire doit être très organisé(e), capable de gérer plusieurs tâches en même temps, et avoir une excellente gestion du temps. De plus, le/la secrétaire doit être en mesure de gérer les communications via différents canaux tels que les messages Facebook, les messages Instagram, les courriels etc. Enfin, il/elle doit veiller à ce que les réservations de salle soient effectuées en temps opportuns et que les activités de l'association puissent se dérouler dans des conditions optimales."/>

                <Accordion title='Trésorier' description="Le rôle du/de la trésorier(ère) est crucial pour la santé financière de l'association. Il/elle doit veiller à une gestion intelligente du budget de l'association en établissant des prévisions budgétaires et en suivant les dépenses. Le/la trésorier(ère) doit avoir de solides compétences en gestion financière et avoir une bonne connaissance des différents mécanismes de financement disponibles pour les associations. En outre, il/elle doit trouver des sources de financement, telles que des subventions, des dons, des levées de fonds ou des partenariats avec des entreprises locales. Cette mission est essentielle pour soutenir le développement et la croissance financière de l'association sur le long terme."/>

                <Accordion title="Vice-président dawah" description="Le/la vice-président(e) dawah est responsable d’organiser des activités qui favorisent l'épanouissement spirituel de notre communauté à travers l'éducation, l'apprentissage et le partage des connaissances. À travers ces activités, il/elle doit viser à enrichir la compréhension de la vie spirituelle et à renforcer notre engagement envers nos valeurs communes religieuses. Le/la vice-président(e) dawah a comme tâche de chercher à établir des contacts avec des organisations islamiques pour trouver des conférenciers pour les événements éducatifs de l'association. En outre, il/elle devrait faire preuve de créativité pour concevoir une variété d'événements éducatifs afin d'atteindre tous les types d'étudiants et de favoriser leur épanouissement spirituel."/>

                <Accordion title='Vice-président humanitaire' description="Le/la vice-président(e) de projet humanitaire est chargé(e) de planifier et d'exécuter des projets humanitaires et communautaires.  Le/la vice-président(e) de projet humanitaire doit organiser et superviser tous les aspects des projets humanitaires et communautaires de l'association. Il/elle doit travailler en étroite collaboration avec les membres de l'association et bénévoles pour s'assurer que les projets sont exécutés de manière efficace. Il/elle est également responsable de la mise en place de partenariats avec des organismes humanitaires et caritatifs. Il/elle doit rechercher des partenaires potentiels, établir des contacts, et négocier les termes et les conditions des partenariats."/>

                <Accordion title='Vice-présidents activités' description="Les deux vice-présidents des activités sont responsables de la gestion et de l'organisation d'activités sociales variées pour la communauté étudiante. Ils doivent veiller au maintien d'une communauté étudiante solidaire et engagée en proposant des événements amusants qui permettent de renforcer les liens entre les étudiants. En outre, les vice-présidents des activités doivent veiller à ce que les événements organisés soient propices à la création de nouveaux contacts et relations entre les étudiants, tout en encourageant la participation active des associations étudiantes. Pour se faire, les vice-présidents doivent user de leur créativité et travailler en étroite collaboration avec les membres de l'association pour identifier les besoins et les intérêts de la communauté étudiante. Ils doivent travailler à créer un environnement chaleureux et accueillant où tous les étudiants se sentent à l'aise de participer et de s'impliquer."/>

                <Accordion title='Vice-président communication' description="Le/la vice-président(e) communication est le/la garant(e) de la visibilité de l'association auprès de la communauté. Il/elle est responsable de la promotion et de la publicité des activités de l’association à travers différents canaux de communication tels que Facebook, la chaîne WhatsApp et Instagram. Un de ses objectifs est également de s'assurer que l'association dispose de matériel photographique de qualité pour promouvoir ses activités et son image auprès du public. En utilisant les images et vidéos prises lors des événements, le/la vice-président(e) des communications va mettre en œuvre sa créativité et son ingéniosité pour créer du contenu publicitaire efficace pour l'association. Il/elle pourra notamment utiliser des plateformes telles que TikTok pour produire des vidéos engageantes et partager des moments forts de l'association avec la communauté. De plus, i/elle peut utiliser ces images pour créer des affiches, des dépliants ou encore des bannières pour le site web de l'association."/>

                <Accordion title='Vice-président externe' description="Le/la vice-président(e) externe est responsable de maintenir une communication régulière avec d'autres associations, en particulier lorsqu'il s'agit d'organiser des activités ou des projets collaboratifs. Il/elle doit établir des partenariats avec d'autres organisations étudiantes ou communautaires afin de renforcer la présence et l'impact de l'association dans la communauté. Il/elle doit également veiller à ce que l'association soit représentée lors d'événements organisés par d'autres associations ou organismes, afin de développer des relations de confiance et de coopération."/>
            </div>
        </div>
    )
}

export default Comites